<template>
  <z-button
    v-tooltip.top="'Descargar CSV'"
    data-test-id="download-btn"
    variant="secondary"
    fit="auto"
    :disabled="loading || selectedFilter === null"
    :loading="loading"
    @click="downloadCSV"
  >
    <font-awesome-icon icon="download" size="lg" />
  </z-button>
</template>

<script>
import FileDownload from "@/fileDownload";
import DateFilters from "@zubut/common/src/constants/filters/date";
import ServiceType from "@zubut/common/src/constants/services/type";
import { format, dateRange, formatToISO } from "@zubut/common/src/utils/time";
import CSVContainers from "@/services/csvContainers";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";

export default {
  name: "TransactionsReportDownload",

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    dateFilterType: {
      type: Number,
      default: null
    },
    until: {
      type: [String, Date],
      default: null
    },
    from: {
      type: [String, Date],
      default: null
    },
    serviceType: {
      type: Number,
      default: null
    },
    selectedFilter: {
      type: String,
      default: null
    },
    searchText: {
      type: String,
      default: null
    },
    cityId: {
      type: String,
      default: null
    },
    clientId: {
      type: Array,
      default: () => []
    },
    driverId: {
      type: String,
      default: null
    }
  },

  methods: {
    downloadCSV() {
      this.updateLoading(true);
      const where = this.buildWhere();
      CSVContainers.generateTransactions({
        where,
        addColumns: this.columns,
        selectedFilter: this.selectedFilter
      })
        .then(
          res => {
            FileDownload(
              res,
              `reporte_transacciones_${format(
                new Date(),
                "Y_MMM_d_H_mm_ss"
              )}.csv`,
              "application/octet-stream"
            );
          },
          err => this.$emit("error", err)
        )
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.updateLoading(false);
        });
    },
    buildWhere() {
      const where = {};

      if (this.searchText) {
        where.destinationId = { like: `%${this.searchText}%` };
      }

      if (this.cityId) {
        where.cityId = this.cityId;
      }

      if (this.clientId.length > 0) {
        where.clientId = { inq: this.clientId };
      }

      if (this.driverId) {
        where.driverId = this.driverId;
      }

      if (this.serviceType === ServiceType.NUM_MULTI_POINT) {
        where.deliveryType = { inq: ServiceType.mulitPointTypes };
      } else if (this.serviceType === ServiceType.NUM_PARCEL) {
        // TODO: Replace this when parcel service type is saved in db, for now when can infer null is for parcels
        where.deliveryType = null;
      } else if (this.serviceType != null) {
        where.deliveryType = this.serviceType;
      }

      // Date Filter
      let from = null;
      let until = null;

      if (
        this.dateFilterType === DateFilters.NUM_CUSTOM &&
        this.from &&
        this.until
      ) {
        from = formatToISO(startOfDay(this.from));
        until = formatToISO(endOfDay(this.until));
      } else if (
        this.dateFilterType !== null &&
        this.dateFilterType !== DateFilters.NUM_CUSTOM
      ) {
        const newDate = dateRange(this.dateFilterType);
        from = formatToISO(startOfDay(newDate.from));
        until = formatToISO(endOfDay(newDate.until));
      }

      if (from && until) {
        where.and = [
          {
            attendedTime: {
              gte: from
            }
          },
          {
            attendedTime: {
              lte: until
            }
          }
        ];
      }

      return where;
    },
    updateLoading(value) {
      this.$emit("update:loading", value);
    }
  }
};
</script>
