<template>
  <div>
    <transactions-modal
      v-model="enableModal"
      :transaction-id="selectedTransaction"
      :company="companyName"
      :delivery-type="deliveryType"
      :new-transaction="newTransaction"
      @hide="disableModal"
      @update-listing="getListing"
    />
    <b-card class="p-3">
      <b-row align-h="between">
        <b-col cols="auto">
          <div class="d-flex mb-2">
            <span class="card-title mr-3 mb-0">Transacciones</span>
            <z-dropdown
              id="dropdown-date"
              v-model="selectedFilter"
              :disabled="readTransactionsWithAccess.length === 1"
              :options="filterTypeOptions"
              :show-header="false"
              name="Modalidad"
            />
          </div>
        </b-col>
        <b-col cols="auto">
          <z-button
            v-tooltip.top="'Refrescar'"
            :disabled="selectedFilter === null"
            fit="auto"
            variant="secondary"
            class="px-4"
            @click="getListing"
          >
            <font-awesome-icon icon="reload" size="lg" />
          </z-button>
          <transactions-report-download
            v-if="hasDownloadButtonPermission"
            class="order-1 order-md-0 mr-md-3 px-4 ml-3"
            :loading.sync="loadingReport"
            :date-filter-type="selectedDate"
            :columns="filteredSelectedColumns"
            :until="dateUntil"
            :from="dateFrom"
            :service-type="selectedService"
            :selected-filter="selectedFilter"
            :search-text="searchText"
            :city-id="selectedCity"
            :client-id="selectedClient"
            :driver-id="selectedDriver"
            @error="handleReportError"
          />
        </b-col>
      </b-row>
      <div>
        <b-row v-show="selectedFilter" class="mt-3 mt-lg-4" align-h="between">
          <b-col xl="10">
            <b-row>
              <b-col lg="4" xl="2">
                <z-search-input
                  v-model="searchText"
                  placeholder="Busca por ID de servicio"
                />
              </b-col>
              <b-col lg="2" class="mt-3 mt-lg-0 mb-lg-3">
                <z-dropdown
                  id="dropdown-date"
                  v-model="selectedDate"
                  :show-header="false"
                  :options="dateOptions"
                  name="Fecha"
                />
              </b-col>
              <b-col
                v-show="showDatePicker"
                cols="12"
                sm="6"
                lg="2"
                class="mt-3 mt-lg-0"
              >
                <z-date-picker
                  v-model="dateFrom"
                  calendar-class="date-picker"
                  :disabled-dates="disableDatesFrom"
                  input-class="form-control bg-white w-100"
                  placeholder="Desde"
                />
              </b-col>
              <b-col
                v-show="showDatePicker"
                cols="12"
                sm="6"
                lg="2"
                class="mt-3 mt-lg-0"
              >
                <z-date-picker
                  v-model="dateUntil"
                  calendar-class="date-picker"
                  :disabled-dates="disableDatesUntil"
                  input-class="form-control bg-white w-100"
                  placeholder="Hasta"
                />
              </b-col>
              <b-col lg="2" class="mt-3 mt-lg-0">
                <z-dropdown
                  id="dropdown-service"
                  v-model="selectedService"
                  :options="serviceTypeOptions"
                  :show-header="false"
                  name="Entrega"
                />
              </b-col>
              <b-col lg="2" class="mt-3 mt-lg-0">
                <z-dropdown-check-list-clients v-model="selectedClient" />
              </b-col>
              <b-col lg="2" class="mt-3 mt-lg-0">
                <z-dropdown-drivers v-model="selectedDriver" />
              </b-col>
              <b-col lg="2" class="mt-3 mt-lg-0">
                <z-dropdown-cities v-model="selectedCity" />
              </b-col>
            </b-row>
          </b-col>
          <b-col xl="2" class="mt-3 mt-lg-0">
            <z-dropdown-check-list
              id="dropdown-role-type"
              ref="dropdownColumns"
              :value="selectedColumns"
              search
              name="Columnas"
              :options="customColumns"
              :show-footer="false"
            >
              <template #footer="{ checked: columns }">
                <z-button @click="setCustomColumns(columns)">
                  Aplicar
                </z-button>
              </template>
            </z-dropdown-check-list>
          </b-col>
        </b-row>
      </div>
      <quick-message
        :show.sync="showQuickMessage"
        :message="quickMessage != null ? quickMessage.message : ''"
        :type="quickMessage != null ? quickMessage.type : 'success'"
        class="mx-2 my-2"
        dismissible
        with-icon
      />
      <b-row>
        <b-col>
          <z-table
            class="mt-4"
            responsive
            :items="listing"
            :fields="fields"
            :loading="isLoading"
            @row-clicked="viewTransaction"
          >
            <template #empty>
              <p class="text-center py-5 my-5 font-weight-semi-bold">
                Debes seleccionar el tipo de filtro para mostrar transacciones
              </p>
            </template>
            <template #head(attendedTime)>
              <div
                class="cursor-pointer"
                style="width: 150px;"
                @click="chageSort"
              >
                Fecha
                <font-awesome-icon
                  v-show="sortOrder === 'asc'"
                  icon="sort"
                  size="lg"
                />
                <font-awesome-icon
                  v-show="sortOrder === 'desc'"
                  icon="sort"
                  size="lg"
                  flip="vertical"
                />
              </div>
            </template>
            <template #cell(transactionId)="row">
              <z-copy-id :text="row.value" />
            </template>
            <template #cell(destinationId)="row">
              <z-copy-id :text="row.value" />
            </template>
            <template #cell(serviceId)="row">
              <z-copy-id :text="row.value" />
            </template>
            <template #cell(concept)="row">
              <z-concept-badge
                v-if="typeof row.value === 'number'"
                :type="row.value"
              />
            </template>
            <template #cell(status)="row">
              <div class="align-linked-transaction">
                <status-transaction
                  v-if="typeof row.value === 'number'"
                  :status="row.value"
                />
                <z-icon
                  v-if="row.item.parentId"
                  v-tooltip.top="`${row.item.parentId}`"
                  name="Stacked"
                  class="stacked-icon mr-2"
                  variant="active"
                />
              </div>
            </template>
            <template #cell(vehicleType)="row">
              <z-vehicle
                v-if="typeof row.value === 'number'"
                :type="row.value"
              />
            </template>
            <template #cell(deliveryType)="row">
              <service-type-badge
                v-if="
                  typeof row.value === 'number' &&
                    !isWalletDeposit(row.item.concept)
                "
                :type="row.value"
              />
            </template>
            <template #cell(driverName)="row">
              <z-shorten-text
                v-if="row.value != null"
                :text="row.value"
                class="max-w-175"
              />
            </template>
            <template #cell(cityName)="row">
              <z-shorten-text
                v-if="row.value != null"
                :text="row.value"
                class="max-w-175"
              />
            </template>
            <template #cell(clientName)="row">
              <z-shorten-text
                v-if="row.value != null"
                :text="row.value"
                class="max-w-175"
              />
            </template>
            <template #cell(options)="row">
              <div class="options-container">
                <b-dropdown
                  menu-class="transactions-dropdown"
                  variant="link"
                  size="md"
                  no-caret
                  dropleft
                  lazy
                  boundary="window"
                  :disabled="
                    selectedFilter === 'DRIVERS_ACCOUNTING' &&
                      !(row.item.service && row.item.service.id) &&
                      !isWalletPayment(row.item.type)
                  "
                >
                  <template slot="button-content">
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>

                  <b-dropdown-item
                    v-if="selectedFilter !== 'DRIVERS_ACCOUNTING'"
                    variant="link"
                    @click="handleNewTransaction(row.item)"
                  >
                    Crear transacción ligada
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="row.item.service && row.item.service.id"
                    variant="link"
                  >
                    <router-link
                      :to="{
                        name: 'serviceDetail',
                        params: { id: row.item.service.id }
                      }"
                    >
                      Ver servicio
                    </router-link>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="isWalletPayment(row.item.type)"
                    @click.stop="handleInvoice(row.item)"
                  >
                    {{
                      row.item.invoiceId
                        ? "Descargar Factura"
                        : "Generar Factura"
                    }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </z-table>
          <z-table-pagination
            :total-rows="listingTotal"
            :per-page.sync="perPage"
            :current-page.sync="currentPage"
          />
        </b-col>
      </b-row>
    </b-card>
    <transactions-settings-modal :show.sync="showSettingsModal" />
  </div>
</template>

<script>
import TransactionsModal from "./TransactionsModal";
import ZTable from "@zubut/common/src/components/ZTable";
import DateFilters from "@zubut/common/src/constants/filters/date";
import StatusTransaction from "@zubut/common/src/components/StatusTransaction";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZConceptBadge from "@zubut/common/src/components/ZConceptBadge";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZDropdownCheckList from "@zubut/common/src/components/ZDropdownCheckList";
import { parseQueryParam } from "@zubut/common/src/utils/strings";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge";
import ZCopyId from "@zubut/common/src/components/ZCopyId";
import ZVehicle from "@zubut/common/src/components/ZVehicle";
import {
  formatISO,
  formatToISO,
  dateRange
} from "@zubut/common/src/utils/time";

import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers";
import ZDropdownCities from "@/app/components/ZDropdownCities";
import ZShortenText from "@zubut/common/src/components/ZShortenText";
import TransactionType from "@/constants/transactions/type";
import TransactionConcept from "@zubut/common/src/constants/transactions/concept";
import Transactions from "@/services/transactions";
import Invoices from "@/services/invoices";
import filtering from "@/mixins/filtering";
import notifyMixin from "@/mixins/notify";
import { formatMoney } from "@/utils/money";
import * as mutation from "@/store/modules/transactions/mutations-types";
import TransactionsSettingsModal from "./TransactionsSettingsModal";
import Columns, { permanentColumns } from "./columns";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import TransactionsReportDownload from "./TransactionsReportDownload";
import Access from "@/constants/access";
import FilterModalityOptions from "@/constants/transactions/filterModalityOptions";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";

const {
  moduleName,
  DRIVERS_ACCOUNTING,
  CUSTOMER_BALANCE,
  TRANSACTION_HISTORY
} = FilterModalityOptions;

export default {
  name: "Transactions",

  components: {
    TransactionsModal,
    ZTablePagination,
    ZSearchInput,
    ServiceTypeBadge,
    StatusTransaction,
    TransactionsReportDownload,
    TransactionsSettingsModal,
    ZConceptBadge,
    ZCopyId,
    ZDatePicker,
    ZDropdown,
    ZDropdownCities,
    ZDropdownCheckListClients,
    ZDropdownCheckList,
    ZDropdownDrivers,
    ZShortenText,
    ZTable,
    ZVehicle
  },

  mixins: [notifyMixin("notifications"), filtering("transactions")],

  data() {
    return {
      access: Access,
      showQuickMessage: false,
      quickMessage: null,
      serviceTypeOptions: [{ text: "Todos", value: null }].concat([
        { value: ServiceType.NUM_MULTI_POINT, text: ServiceType.MULTI_POINT },
        {
          value: ServiceType.NUM_RENT_PER_HOUR,
          text: ServiceType.RENT_PER_HOUR
        },
        { value: ServiceType.NUM_PARCEL, text: ServiceType.PARCEL },
        {
          value: ServiceType.NUM_PACKAGE_DELIVERY,
          text: "Rutas"
        }
      ]),
      selected: {
        user: {
          name: "",
          wallet: {
            id: "",
            isWallet: true
          },
          creditCards: []
        },
        service: { id: "" },
        amount: 0,
        subtotal: 0,
        tax: 0
      },
      isLoading: false,
      sortBy: "attendedTime",
      sortOrder: "desc",
      showChargeModal: false,
      dateOptions: [{ text: "Selecciona un rango", value: null }].concat(
        DateFilters.options.filter(op =>
          [
            DateFilters.NUM_TODAY,
            DateFilters.NUM_THIS_WEEK,
            DateFilters.NUM_THIS_MONTH,
            DateFilters.NUM_CUSTOM
          ].includes(op.value)
        )
      ),
      customColumns: Columns,
      showSettingsModal: true,
      selectedTransaction: "",
      companyName: "",
      deliveryType: 0,
      showModal: false,
      newTransaction: false,
      loadingReport: false
    };
  },

  computed: {
    hasDownloadButtonPermission() {
      const permissionDirectory = {
        [TRANSACTION_HISTORY]: Access.transactions.HAS_ACCESS_EXPORT_TRANSACTIONS(),
        [DRIVERS_ACCOUNTING]: Access.transactions.HAS_ACCESS_EXPORT_DRIVER_TRANSACTIONS(),
        [CUSTOMER_BALANCE]: Access.transactions.HAS_ACCESS_EXPORT_CUSTOMER_TRANSACTIONS()
      };
      const hasDownloadButtonPermission = this.selectedFilter
        ? permissionDirectory[this.selectedFilter]
        : true;
      return hasDownloadButtonPermission;
    },

    readTransactionsWithAccess() {
      const permissionDirectory = {
        [TRANSACTION_HISTORY]: Access.transactions.HAS_ACCESS_READ_TRANSACTIONS(),
        [DRIVERS_ACCOUNTING]: Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS(),
        [CUSTOMER_BALANCE]: Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS()
      };

      const readTransactionsWithAccess = [];

      for (const property in permissionDirectory) {
        if (permissionDirectory[property] === true) {
          readTransactionsWithAccess.push({
            [property]: permissionDirectory[property]
          });
        }
      }

      return readTransactionsWithAccess;
    },
    filterTypeOptions() {
      const filterTypeOptions = [
        { text: "Selecciona un tipo filtro", value: null }
      ];

      if (Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS()) {
        filterTypeOptions.push({
          text: "Corte de conductores",
          value: DRIVERS_ACCOUNTING
        });
      }

      if (Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS()) {
        filterTypeOptions.push({
          text: "Saldos de clientes",
          value: CUSTOMER_BALANCE
        });
      }

      if (Access.transactions.HAS_ACCESS_READ_TRANSACTIONS()) {
        filterTypeOptions.push({
          text: "Transacciones",
          value: TRANSACTION_HISTORY
        });
      }
      return filterTypeOptions;
    },
    fields() {
      const fields = [
        {
          key: "options",
          label: "Opciones",
          class: "text-left",
          stickyColumn: true,
          isRowHeader: true,
          thStyle: {
            width: "74px"
          }
        }
      ];
      this.selectedColumns.forEach(col => {
        if (col === "transactionId") {
          fields.push({
            key: "transactionId",
            label: "ID Transacción",
            thStyle: {
              minWidth: "150px"
            }
          });
        } else if (col === "driverMuId") {
          fields.push({
            key: "driverMuId",
            label: "ID MU",
            thStyle: {
              minWidth: "100px"
            }
          });
        } else if (col === "status") {
          fields.push({
            key: "status",
            label: "Status",
            thStyle: {
              minWidth: "100px"
            }
          });
        } else if (col === "destinationId") {
          fields.push({
            key: "destinationId",
            label: "ID Destino",
            thStyle: {
              minWidth: "125px"
            }
          });
        } else if (col === "serviceId") {
          fields.push({
            key: "serviceId",
            label: "ID Servicio",
            thStyle: {
              minWidth: "125px"
            }
          });
        } else if (col === "deliveryType") {
          fields.push({
            key: "deliveryType",
            label: "Tipo de Entrega",
            thStyle: {
              minWidth: "150px"
            }
          });
        } else if (col === "attendedTime") {
          fields.push({
            key: "attendedTime",
            label: "Fecha de Atención",
            formatter: val => val && formatISO(val, "d 'de' LLLL 'de' Y H:mm"),
            thStyle: {
              minWidth: "225px"
            }
          });
        } else if (col === "day") {
          fields.push({
            key: "day",
            label: "Día",
            thStyle: {
              minWidth: "50px"
            }
          });
        } else if (col === "month") {
          fields.push({
            key: "month",
            label: "Mes",
            thStyle: {
              minWidth: "50px"
            }
          });
        } else if (col === "week") {
          fields.push({
            key: "week",
            label: "Semana"
          });
        } else if (col === "year") {
          fields.push({
            key: "year",
            label: "Año",
            thStyle: {
              minWidth: "50px"
            }
          });
        } else if (col === "concept") {
          fields.push({
            key: "concept",
            label: "Concepto",
            thStyle: {
              minWidth: "150px"
            }
          });
        } else if (col === "cityName") {
          fields.push({
            key: "cityName",
            label: "Ciudad"
          });
        } else if (col === "vehicleType") {
          fields.push({
            key: "vehicleType",
            label: "Vehículo",
            class: "text-center",
            thStyle: {
              minWidth: "100px"
            }
          });
        } else if (col === "driverBillingName") {
          fields.push({
            key: "driverBillingName",
            label: "Razón Social Mensajero"
          });
        } else if (col === "driverName") {
          fields.push({
            key: "driverName",
            label: "Mensajero"
          });
        } else if (col === "driverRfc") {
          fields.push({
            key: "driverRfc",
            label: "RFC Mensajero"
          });
        } else if (col === "driverPaymentId") {
          fields.push({
            key: "driverPaymentId",
            label: "Zubut ID"
          });
        } else if (col === "clientBillingName") {
          fields.push({
            key: "clientBillingName",
            label: "Razón Social Cliente"
          });
        } else if (col === "clientName") {
          fields.push({
            key: "clientName",
            label: "Cliente"
          });
        } else if (col === "clientRfc") {
          fields.push({
            key: "clientRfc",
            label: "RFC Cliente"
          });
        } else if (col === "units") {
          fields.push({
            key: "units",
            label: "Unidades"
          });
        } else if (col === "unitCost") {
          fields.push({
            key: "unitCost",
            label: "Costo Unitario",
            formatter: value => formatMoney(value),
            thStyle: {
              minWidth: "150px"
            }
          });
        } else if (col === "subtotal") {
          fields.push({
            key: "subtotal",
            label: "Subtotal",
            formatter: value => formatMoney(value)
          });
        } else if (col === "tax") {
          fields.push({
            key: "tax",
            label: "IVA",
            formatter: value => formatMoney(value)
          });
        } else if (col === "total") {
          fields.push({
            key: "total",
            label: "Total",
            formatter: value => formatMoney(value)
          });
        } else if (col === "driverEarningsPercentage") {
          fields.push({
            key: "driverEarningsPercentage",
            label: "Porcentaje Mensajero",
            formatter: value => `${value * 100}%`,
            thStyle: {
              minWidth: "175px"
            }
          });
        } else if (col === "ivaRetention") {
          fields.push({
            key: "ivaRetention",
            label: "Retención IVA",
            formatter: value => formatMoney(value),
            thStyle: {
              minWidth: "125px"
            }
          });
        } else if (col === "isrRetention") {
          fields.push({
            key: "isrRetention",
            label: "Retención ISR",
            formatter: value => formatMoney(value),
            thStyle: {
              minWidth: "125px"
            }
          });
        } else if (col === "driverEarnings") {
          fields.push({
            key: "driverEarnings",
            label: "Ganancia de Mensajero",
            formatter: value => formatMoney(value),
            thStyle: {
              minWidth: "200px"
            }
          });
        }
      });
      return fields;
    },
    selectedColumns: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterColumns`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_COLUMNS}`,
          val
        );
      }
    },
    listing() {
      return this.selectedColumns.length && this.selectedFilter
        ? this.$store.getters[`${moduleName}/getLisintg`]
        : [];
    },
    selectedFilter: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterType`];
      },
      set(val) {
        this.setFilter("filter", val);
        this.selectedDate = this.selectedDate ?? DateFilters.NUM_THIS_WEEK;
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_TYPE}`,
          val
        );
      }
    },
    selectedService: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterService`];
      },
      set(val) {
        this.setFilter("service", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SERVICE}`,
          val
        );
      }
    },
    selectedDate: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDate`];
      },
      set(val) {
        this.setFilter("date", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE}`,
          val
        );
      }
    },
    dateFrom: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateFrom`];
      },
      set(val) {
        this.setFilter("from", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_FROM}`,
          val
        );
      }
    },
    dateUntil: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateUntil`];
      },
      set(val) {
        this.setFilter("until", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_UNTIL}`,
          val
        );
      }
    },
    selectedClient: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterClient`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_CLIENT}`,
          val
        );
      }
    },
    selectedDriver: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDriver`];
      },
      set(val) {
        this.setFilter("driver", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DRIVER}`,
          val
        );
      }
    },
    selectedCity: {
      get() {
        return this.$store.getters[`cities/getCity`];
      },
      set(val) {
        this.setFilter("city", val);
      }
    },
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterSearch`];
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SEARCH}`,
          val
        );
      }
    },
    showCleanfilter() {
      return this.selectedFilter !== null;
    },
    listingTotal() {
      return this.$store.getters[`${moduleName}/getListingTotal`];
    },
    disableDatesFrom() {
      let date;
      if (this.dateUntil != null) {
        date = new Date(this.dateUntil);
      } else {
        date = new Date();
      }
      return { from: date };
    },
    disableDatesUntil() {
      let date;
      if (this.dateFrom != null) {
        date = new Date(this.dateFrom);
      } else {
        date = new Date();
      }
      return { to: date };
    },
    showDatePicker() {
      return this.selectedDate === DateFilters.NUM_CUSTOM;
    },
    enableModal() {
      return this.showModal;
    },
    /* Permanent columns should not be sent to the listing endpoint */
    filteredSelectedColumns() {
      return this.selectedColumns.filter(
        col => !permanentColumns.includes(col)
      );
    }
  },

  watch: {
    quickMessage: "renderQuickMessage",
    selectedClient() {
      this.getListing();
    }
  },

  created() {
    this.hideTransactionsSettingsModalWithOneItem();
    this.setRouteFilters();

    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getListing(), 100);
    this.searchFilterListener = _debounce(() => this.getListing(), 600);

    if (this.selectedFilter) {
      this.defaultListener();
    }

    this.search = _debounce(() => {
      this.getListing();
    }, 600);
  },

  methods: {
    hideTransactionsSettingsModalWithOneItem() {
      if (this.readTransactionsWithAccess.length === 1) {
        this.showSettingsModal = false;
        this.selectedFilter = Object.keys(
          this.readTransactionsWithAccess[0]
        )[0];
      }
    },
    setRouteFilters() {
      const query = { ...this.$route.query };

      if (_isEmpty(query) && this.selectedFilter !== null) {
        const FIRST_PAGE = 1;
        query.page = FIRST_PAGE;
        query.filter = this.selectedFilter;
        query.search = this.searchText;
        query.date = this.selectedDate;
        query.from = this.dateFrom;
        query.until = this.dateUntil;
        query.driver = this.selectedDriver;
        query.service = this.selectedService;
        query.city = this.selectedCity;
        this.$router
          .replace({
            name: this.$route.name,
            query
          })
          .catch(() => {});
      }

      if (_isEmpty(query) && this.selectedFilter === null) {
        this.showSettingsModal = true;
      } else {
        this.showSettingsModal = false;

        query.filter = parseQueryParam(query.filter);
        query.search = parseQueryParam(query.search);
        query.page = parseQueryParam(query.page);
        query.service = parseQueryParam(query.service);
        query.date = parseQueryParam(query.date);
        query.from = parseQueryParam(query.from);
        query.until = parseQueryParam(query.until);
        query.client = parseQueryParam(query.client);
        query.driver = parseQueryParam(query.driver);
        query.city = parseQueryParam(query.city);

        this.selectedFilter = query.filter ?? null;
        this.searchText = query.search ?? null;
        this.selectedDate = query.date ?? null;
        this.dateFrom = query.from ? new Date(query.from) : null;
        this.dateUntil = query.until ? new Date(query.until) : null;
        this.selectedDriver = query.driver ?? null;
        this.selectedService = query.service ?? null;
        this.selectedCity = query.city ?? null;

        this.$router
          .replace({
            name: this.$route.name,
            query
          })
          .catch(() => {});
      }
    },

    renderQuickMessage() {
      this.showQuickMessage = true;
    },

    isWalletPayment(type) {
      return type === TransactionType.NUM_WALLET_PAYMENT;
    },

    isWalletDeposit(concept) {
      return concept === TransactionConcept.NUM_WALLET_DEPOSIT;
    },

    buildWhere() {
      const where = {};

      if (this.searchText) {
        where.deliveryId = { ilike: `%${this.searchText}%` };
      }

      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }

      if (this.selectedClient.length > 0) {
        where.clientId = { inq: this.selectedClient };
      }

      if (this.selectedDriver) {
        where.driverId = this.selectedDriver;
      }

      if (this.selectedService === ServiceType.NUM_MULTI_POINT) {
        where.deliveryType = { inq: ServiceType.mulitPointTypes };
      } else if (this.selectedService === ServiceType.NUM_PARCEL) {
        // TODO: Replace this when parcel service type is saved in db, for now when can infer null is for parcels
        where.deliveryType = null;
      } else if (this.selectedService != null) {
        where.deliveryType = this.selectedService;
      }

      //Date Filter
      let from = null;
      let until = null;

      if (
        this.selectedDate === DateFilters.NUM_CUSTOM &&
        this.dateFrom &&
        this.dateUntil
      ) {
        from = formatToISO(startOfDay(this.dateFrom));
        until = formatToISO(endOfDay(this.dateUntil));
      } else if (
        this.selectedDate !== null &&
        this.selectedDate !== DateFilters.NUM_CUSTOM
      ) {
        const newDate = dateRange(this.selectedDate);
        from = formatToISO(startOfDay(newDate.from));
        until = formatToISO(endOfDay(newDate.until));
      }

      if (from && until) {
        where.and = [
          {
            attendedTime: {
              gte: from
            }
          },
          {
            attendedTime: {
              lte: until
            }
          }
        ];
      }

      return where;
    },

    handleInvoice(transaction) {
      transaction.invoiceId
        ? this.getInvoiceData(transaction.id, transaction.invoiceId)
        : this.createInvoice(transaction.id);
    },

    getInvoiceData(id, invoiceId) {
      this.isLoading = true;
      const where = { id: invoiceId };
      const filter = { limit: 1 };
      Invoices.listing({ filter, where })
        .then(({ data }) => {
          if (data[0].satWsId) {
            this.getInvoice(id, data[0].satWsId);
          } else {
            this.notify({
              type: "error",
              title: "Error al obtener factura",
              text: error?.message
            });
          }
        })
        .catch(error => {
          this.notify({
            type: "error",
            title: "Error al obtener factura",
            text: error?.message
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createInvoice(id) {
      this.isLoading = true;
      Transactions.createInvoice(id)
        .then(data => {
          this.notify({
            type: "success",
            title: "Factura Generada correctamente"
          });
          this.getInvoice(id, data.satWsId);
        })
        .catch(error => {
          this.notify({
            type: "error",
            title: "Error al generar factura",
            text: error?.message
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getInvoice(id, satWsId) {
      this.isLoading = true;
      Invoices.download({ satWsId: satWsId, formats: ["pdf", "xml"] })
        .then(response => {
          const blob = new Blob([response]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `servicio-${id}.zip`;
          link.click();
          this.notify({
            type: "success",
            title: "Descargando Factura"
          });
        })
        .catch(error => {
          this.notify({
            type: "error",
            title: "Error al generar factura",
            text: error?.message
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getListing() {
      if (!this.selectedFilter) return;
      this.isLoading = true;
      const where = this.buildWhere();
      this.$store
        .dispatch("transactions/getListing", {
          where,
          filter: {
            ...this.pagination,
            order: `${this.sortBy} ${this.sortOrder}`
          },
          addColumns: this.filteredSelectedColumns,
          selectedFilter: this.selectedFilter
        })
        .catch(error => {
          this.notify({
            type: "error",
            title: "Error al obtener transacciones",
            text: error?.message
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    chageSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.getListing();
    },

    viewTransaction(item) {
      this.newTransaction = false;
      this.companyName = item.clientName;
      this.selectedTransaction = item.transactionId;
      this.deliveryType = item.deliveryType;
      this.showModal = true;
    },

    handleNewTransaction(item) {
      this.newTransaction = true;
      this.companyName = item.clientName;
      this.selectedTransaction = item.transactionId;
      this.deliveryType = item.deliveryType;
      this.showModal = true;
    },

    disableModal() {
      this.showModal = false;
    },

    handleReportError(err) {
      if (err.statusCode === 404) {
        this.showQuickMessage = true;
        this.quickMessage.message =
          "Error al exportar datos, no hay datos disponibles.";
        this.quickMessage.type = "error";
      } else {
        this.$captureError(err);
      }
    },

    setCustomColumns(columns) {
      this.selectedColumns = columns;
      this.getListing();
      this.$refs.dropdownColumns.hide(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & button:first-child {
    padding-left: 0px;
  }

  & button:last-child {
    padding-right: 0px;
  }
}

.align-linked-transaction {
  display: flex;
  .stacked-icon {
    margin-left: 10px;
  }
}

.max-w-175 {
  max-width: 175px;
}

::v-deep .transactions-dropdown {
  left: 30px !important;
  .dropdown-item {
    font-size: 12px;
    color: $charcoal;
    &:hover,
    &:active {
      background-color: rgba($royal-blue, 0.06) !important;
    }
    a {
      color: $charcoal;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }
}
</style>
