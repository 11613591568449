<template>
  <z-modal :show="value" size="lg" @hide="hideModal">
    <quick-message
      :message="errorMessage"
      :show="hasError"
      type="error"
      with-icon
      class="mb-2"
      @update:show="hasError = $event"
    />
    <div v-if="loading">
      <loading-spinner />
    </div>
    <div v-else>
      <div
        class="transaction-modal-header d-flex justify-content-between pb-3 text-sm"
      >
        <div>
          <div class="text-xl font-weight-bolder mb-2">
            Transacción ID {{ shortTransactionId }}
          </div>
          <div class="d-flex align-items-end">
            <div>Entrega ID: {{ shortDestinationId }}</div>
            <status-destination class="ml-2" :status="statusDestination" />
          </div>
          <div>{{ company }}</div>
          <div>{{ created }}</div>
        </div>
        <div>
          <service-type-badge :type="deliveryType" />
          <status-transaction class="ml-2" :status="status" />
        </div>
      </div>

      <div class="transaction-modal-form pb-3">
        <b-form-group
          v-if="newTransaction"
          label="Tipo de Transacción"
          class="text-sm"
        >
          <b-form-select
            v-model="type"
            class="z-input-group"
            :options="options"
          />
        </b-form-group>
        <div class="d-lg-flex justify-content-between">
          <b-form-group label="Unidad" class="flex-2 text-sm">
            <b-input-group class="z-input-group">
              <b-form-input
                v-model="unit"
                type="number"
                min="0"
                :disabled="!newTransaction && !edit"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Costo Unitario" class="flex-2 text-sm">
            <b-input-group
              class="z-input-group"
              :class="!newTransaction && !edit ? 'disabled' : null"
              :prepend="negativeTransaction ? '$ -' : '$'"
            >
              <b-form-input
                v-model="unitCost"
                type="number"
                class="border-left-0"
                min="0"
                :disabled="!newTransaction && !edit"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="d-lg-flex justify-content-between">
          <b-form-group label="Subtotal" class="flex-3 text-sm">
            <b-input-group
              class="z-input-group disabled"
              :prepend="negativeTransaction ? '$ -' : '$'"
            >
              <b-form-input
                v-model="subTotal"
                type="number"
                class="border-left-0"
                disabled
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="IVA" class="flex-3 text-sm">
            <b-input-group
              class="z-input-group"
              :class="!edit ? 'disabled' : null"
              append="%"
            >
              <b-form-input
                v-model="tax"
                type="number"
                class="border-right-0"
                :disabled="!edit"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Total" class="flex-3 text-sm">
            <b-input-group
              class="z-input-group disabled"
              :prepend="negativeTransaction ? '$ -' : '$'"
            >
              <b-form-input
                v-model="total"
                type="number"
                class="border-left-0"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </div>
        <b-form-group label="Comentarios" class="text-sm">
          <b-input-group class="z-input-group">
            <b-form-input
              v-model="comments"
              type="text"
              :disabled="!newTransaction && !edit"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <div v-if="!newTransaction" class="transactions-table">
        <div class="textmdg font-weight-bold">
          Actividad
        </div>
        <z-table
          class="mt-4"
          hover
          responsive="sm"
          :busy="isLoading"
          :items="listing"
          :fields="fields"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <loading-spinner />
            </div>
          </template>
          <template v-slot:empty>
            <p class="text-center py-3 font-weight-semi-bold">
              No hay actividades registradas
            </p>
          </template>

          <!-- Change styles in cells -->
          <template v-slot:cell(rol)="row">
            {{ rolText(row.item) }}
          </template>

          <template v-slot:cell(severity)="row">
            <div class="text-center">
              <z-icon :name="priority(row.item.severity)" size="20" />
            </div>
          </template>
        </z-table>
      </div>
      <div class="d-flex mt-4">
        <z-button
          variant="secondary"
          class="mr-3"
          @click="edit ? cancelEdit() : hideModal()"
          >Cancelar</z-button
        >
        <z-button
          v-if="
            $can(
              access.transactions.READ_TRANSACTIONS,
              access.transactions.moduleName
            ) &&
              !newTransaction &&
              !edit
          "
          class="mr-3"
          @click="handleEdit()"
          >Editar</z-button
        >
        <z-button
          v-if="false && !newTransaction && status === pay"
          :disabled="!validateForm"
          @click="handleMakeCharge()"
          >Cobrar</z-button
        >
        <z-button
          v-if="newTransaction || edit"
          :disabled="!validateForm && !edit"
          @click="edit ? handleEditTransaction() : handleNewTransaction()"
          >Guardar Cambios</z-button
        >
      </div>
    </div>
  </z-modal>
</template>

<script>
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZModal from "@zubut/common/src/components/ZModal";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge";
import Priority from "@zubut/common/src/constants/logs/priority";
import Users from "@zubut/common/src/constants/logs/users";
import StatusTransaction from "@zubut/common/src/components/StatusTransaction";
import StatusDestination from "@zubut/common/src/components/StatusDestination";
import QuickMessage from "@zubut/common/src/components/QuickMessage.vue";
import ConstTransactions from "@zubut/common/src/constants/transactions/status";
import TransactionConcept from "@zubut/common/src/constants/transactions/concept";
import Transactions from "@/services/transactions";
import TransactionsReport from "@/services/transaction-report";
import { makeShortUUID } from "@/utils/strings";
import notifyMixin from "@/mixins/notify";
import { formatISO } from "@zubut/common/src/utils/time";
import Access from "@/constants/access";
import currency from "currency.js";

export default {
  name: "TransactionsModal",

  components: {
    ZModal,
    ZTable,
    ServiceTypeBadge,
    StatusTransaction,
    StatusDestination,
    QuickMessage
  },

  mixins: [notifyMixin("notifications")],

  props: {
    value: {
      type: Boolean,
      default: false
    },

    transactionId: {
      type: String,
      default: ""
    },

    company: {
      type: String,
      default: ""
    },

    deliveryType: {
      type: Number,
      default: 0
    },

    newTransaction: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      edit: false,
      originalTransaction: null,
      access: Access,
      status: 2,
      statusDestination: 0,
      type: null,
      destinationId: "",
      options: [
        { value: null, text: "Selecciona una opción", disabled: true },
        { value: 0, text: "Reembolso a Cliente" },
        { value: 1, text: "Cobro Extra" }
      ],
      creationDate: "",
      unit: 0,
      unitCost: 0,
      tax: 16,
      comments: "",
      listing: [],
      fields: [
        {
          key: "severity",
          label: "Severidad"
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val => formatISO(val, "d 'de' LLLL 'de' Y H:mm")
        },
        { key: "title", label: "Actividad" },
        { key: "owner", label: "Responsable" },
        { key: "rol", label: "Rol" }
      ],
      sortOrder: "asc",
      isLoading: false,
      loading: false,
      errorMessage: "",
      hasError: false
    };
  },

  computed: {
    shortTransactionId() {
      return makeShortUUID(this.transactionId);
    },

    shortDestinationId() {
      return makeShortUUID(this.destinationId);
    },

    unitCostPrice() {
      return currency(this.unitCost);
    },

    subTotal() {
      return currency(this.unit).multiply(this.unitCost).value;
    },

    total() {
      return currency(this.subTotal)
        .multiply(this.tax)
        .divide(100)
        .add(this.subTotal).value;
    },

    isAdmin() {
      return Users.NUM_ADMIN;
    },

    created() {
      return this.creationDate
        ? formatISO(this.creationDate, "d 'de' LLLL 'de' Y H:mm")
        : "";
    },

    validateForm() {
      return this.type !== null && this.unit > 0 && this.unitCost > 0;
    },

    pay() {
      return ConstTransactions.NUM_PAY;
    },

    selectedFilter() {
      return this.$store.getters[`transactions/getListingFilterType`];
    },

    negativeTransaction() {
      return (
        this.selectedFilter !== "DRIVERS_ACCOUNTING" &&
        this.type === 1 &&
        this.newTransaction
      );
    }
  },

  watch: {
    value(newVal) {
      if (newVal) this.getDetails();
    }
  },

  methods: {
    getDetails() {
      this.loading = true;
      this.resetValues();
      TransactionsReport.details(this.transactionId)
        .then(res => {
          this.originalTransaction = res;
          this.originalTransaction.tax = parseFloat(
            ((res.tax * 100) / res.subtotal).toFixed(2)
          );
          this.assignValues();
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    assignValues() {
      this.originalTransaction = this.originalTransaction;
      this.destinationId = this.originalTransaction.destinationId;
      this.statusDestination = this.originalTransaction.destinationStatus;
      this.status = this.originalTransaction.status;
      this.creationDate = this.originalTransaction.createdAt;
      this.tax = this.originalTransaction.tax;
      if (!this.newTransaction) {
        this.unit = parseInt(this.originalTransaction.units, 10);
        this.unitCost = parseFloat(this.originalTransaction.unitCost);
        this.comments = this.originalTransaction.comment || "";
        this.listing = this.originalTransaction.logs;
      }
    },

    resetValues() {
      this.type = null;
      this.destinationId = "";
      this.statusDestination = 0;
      this.status = 0;
      this.creationDate = Date.now();
      this.unit = 0;
      this.unitCost = 0;
      this.comments = "";
      this.listing = [];
    },

    hideModal() {
      this.edit = false;
      this.$emit("hide");
    },

    priority(type) {
      return Priority.get[type] + "Priority";
    },

    rolText(item) {
      if (item.rol === this.isAdmin) {
        return Users.getAdmin[item.adminType];
      } else return Users.get[item.rol];
    },

    handleMakeCharge() {
      return;
    },

    handleNewTransaction() {
      const data = {};
      const adjusment = 1;
      this.type === adjusment
        ? (data.unitCost = this.unitCost * -1)
        : (data.unitCost = parseFloat(this.unitCost));
      data.units = parseInt(this.unit, 10);
      data.comments = this.comments;
      Transactions.linkedTransaction(this.transactionId, data)
        .then(res => {
          this.notify({
            type: "success",
            title: "Transacción Generada correctamente"
          });
          this.$emit("update-listing");
          this.$emit("hide");
          this.errorMessage = "";
        })
        .catch(err => {
          this.errorMessage =
            "Error al intentar guardar transacción ligada. Contacta a soporte help@zubut.com";
          this.hasError = true;
          this.$captureError(err);
        });
    },

    handleEdit() {
      this.edit = true;
    },

    cancelEdit() {
      this.assignValues();
      this.edit = false;
    },

    handleEditTransaction() {
      const data = {};
      data.unitCost = parseFloat(this.unitCost);
      data.units = parseInt(this.unit, 10);
      data.tax = parseFloat(this.tax);
      data.comments = this.comments || "";
      Transactions.editTransaction(this.transactionId, data)
        .then(res => {
          this.notify({
            type: "success",
            title: "Transacción editada correctamente"
          });
          this.$emit("update-listing");
          this.errorMessage = "";
          this.getDetails();
        })
        .catch(err => {
          this.errorMessage = "Error al intentar editar la transaccion";
          this.hasError = true;
          this.$captureError(err);
        })
        .finally(() => {
          this.edit = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/z-input-group.scss";

@include media-breakpoint-up(lg) {
  .transactions-modal {
    max-width: 40% !important;
  }
  .flex-2 {
    width: 49%;
  }

  .flex-3 {
    width: 32%;
  }
}
</style>
