<template>
  <z-modal
    centered
    size="md"
    hide-close
    :show="show"
    @hide="$emit('update:show', $event)"
  >
    <div class="text-2xl font-weight-bold mb-4">Elige tu modalidad</div>
    <transactions-settings-options
      v-for="settingOption in settingsOptions"
      :key="settingOption.option"
      :selected.sync="selected"
      :setting-option="settingOption"
    />
    <div class="mt-5">
      <z-button variant="secondary" class="mr-3" @click="closeModal">
        Cancelar
      </z-button>
      <z-button :disabled="!selected" @click="handleAceept">
        Continuar
      </z-button>
    </div>
  </z-modal>
</template>

<script>
import Access from "@/constants/access";
import TransactionsSettingsOptions from "./TransactionsSettingsOptions";
import ZModal from "@zubut/common/src/components/ZModal";
import * as mutation from "@/store/modules/transactions/mutations-types";
import DateFilter from "@zubut/common/src/constants/filters/date";
import filtering from "@/mixins/filtering";
import FilterModalityOptions from "@/constants/transactions/filterModalityOptions";

const {
  moduleName,
  DRIVERS_ACCOUNTING,
  CUSTOMER_BALANCE,
  TRANSACTION_HISTORY
} = FilterModalityOptions;

export default {
  name: "TransactionsSettingsModal",

  components: {
    TransactionsSettingsOptions,
    ZModal
  },

  mixins: [filtering("transactions")],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: "",
      DRIVERS_ACCOUNTING
    };
  },

  computed: {
    settingsOptions() {
      const settingsOptions = [];

      if (Access.transactions.HAS_ACCESS_READ_DRIVER_TRANSACTIONS()) {
        settingsOptions.push({
          option: DRIVERS_ACCOUNTING,
          title: "Corte de conductores"
        });
      }

      if (Access.transactions.HAS_ACCESS_READ_CUSTOMER_TRANSACTIONS()) {
        settingsOptions.push({
          option: CUSTOMER_BALANCE,
          title: "Saldos de clientes"
        });
      }

      if (Access.transactions.HAS_ACCESS_READ_TRANSACTIONS()) {
        settingsOptions.push({
          option: TRANSACTION_HISTORY,
          title: "Transacciones"
        });
      }
      return settingsOptions;
    },
    selectedFilter: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterType`];
      },
      set(val) {
        this.setFilter("filter", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_TYPE}`,
          val
        );
      }
    },
    selectedDate: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDate`];
      },
      set(val) {
        this.setFilter("date", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE}`,
          val
        );
      }
    }
  },

  methods: {
    accountingMode() {
      this.selectedFilter = this.selected;
      this.selectedDate = DateFilter.NUM_THIS_WEEK;
    },
    handleAceept() {
      this.accountingMode();
      this.closeModal();
    },
    closeModal() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
