<template>
  <z-badge class="service-type-badge" data-test-id="content">
    {{ displayContent }}
  </z-badge>
</template>

<script>
import TransactionsConcept from "../constants/transactions/concept";
import ZBadge from "./ZBadge";

export default {
  name: "ZConceptBadge",

  components: {
    ZBadge
  },

  props: {
    type: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      defaultColor: "red",
      defaultContent: "S"
    };
  },

  computed: {
    displayContent() {
      return TransactionsConcept.get[this.type];
    },
    backgroundColor() {
      return "#ECF5FE";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.service-type-badge {
  background-color: $alice-blue;
  border-radius: 6px;
  color: $international-klein-blue;
  font-family: Montserrat;
  font-size: 0.85rem;
  font-weight: 500 !important;
  padding: 8px 6px !important;
}
</style>
