const columns = [
  { value: "driverMuId", text: "ID MU" },
  { value: "transactionId", text: "ID Transacción" },
  { value: "status", text: "Status" },
  { value: "destinationId", text: "ID Destino" },
  { value: "serviceId", text: "ID Servicio" },
  {
    value: "deliveryType",
    text: "Tipo de Entrega"
  },
  { value: "attendedTime", text: "Tiempo de Aceptación" },
  { value: "day", text: "Día" },
  { value: "month", text: "Mes" },
  { value: "week", text: "Semana" },
  { value: "year", text: "Año" },
  {
    value: "concept",
    text: "Concepto"
  },
  { value: "cityName", text: "Ciudad" },
  { value: "vehicleType", text: "Vehículo" },
  { value: "driverBillingName", text: "Razón Social Mensajero" },
  { value: "driverName", text: "Mensajero" },
  { value: "driverRfc", text: "RFC Mensajero" },
  { value: "driverPaymentId", text: "Zubut ID" },
  { value: "clientBillingName", text: "Razón Social Cliente" },
  { value: "clientName", text: "Cliente" },
  { value: "clientRfc", text: "RFC Cliente" },
  { value: "units", text: "Unidades" },
  { value: "unitCost", text: "Costo Unitario" },
  { value: "subtotal", text: "Subtotal" },
  { value: "tax", text: "IVA" },
  { value: "total", text: "Total" },
  { value: "driverEarningsPercentage", text: "Porcentaje Mensajero" },
  {
    value: "ivaRetention",
    text: "Retención IVA"
  },
  {
    value: "isrRetention",
    text: "Retención ISR"
  },
  {
    value: "driverEarnings",
    text: "Ganancia de Mensajero"
  }
];

/* This columns will always be returned by the listing endpoint */
export const permanentColumns = [
  "deliveryType",
  "destinationId",
  "transactionId",
  "attendedTime",
  "destinationStatus",
  "concept",
  "clientName",
  "subtotal",
  "tax",
  "total",
  "parentId",
  "serviceId"
];

export default columns;
