const PAY = "Por Pagar";
const PAID = "Pagado";
const CREDIT = "Crédito";

const NUM_PAY = 0;
const NUM_PAID = 1;
const NUM_CREDIT = 2;

const get = {};
get[PAY] = NUM_PAY;
get[PAID] = NUM_PAID;
get[CREDIT] = NUM_CREDIT;
get[NUM_PAY] = PAY;
get[NUM_PAID] = PAID;
get[NUM_CREDIT] = CREDIT;

export default {
  get,
  PAY,
  PAID,
  CREDIT,
  NUM_PAY,
  NUM_PAID,
  NUM_CREDIT
};
