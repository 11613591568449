<template>
  <div
    role="button"
    :class="{ selected: selected === settingOption.option }"
    class="setting-option mb-3"
    @click="$emit('update:selected', settingOption.option)"
  >
    <div class="text-lg font-weight-bold">{{ settingOption.title }}</div>
  </div>
</template>

<script>
import FilterModalityOptions from "@/constants/transactions/filterModalityOptions";

const {
  DRIVERS_ACCOUNTING,
  CUSTOMER_BALANCE,
  TRANSACTION_HISTORY
} = FilterModalityOptions;

const validator = val =>
  ["", DRIVERS_ACCOUNTING, CUSTOMER_BALANCE, TRANSACTION_HISTORY].indexOf(val) >
  -1;

export default {
  name: "TransactionsSettingsOptions",
  props: {
    selected: {
      type: String,
      default: "",
      validator
    },
    settingOption: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.setting-option {
  padding: 1em;
  border: 1px solid $solitude;
  border-radius: 10px;
  transition: all 300ms ease;

  &:hover {
    border: 1px solid $link-water;
  }

  &.selected,
  &.selected:hover {
    border-color: $zubut-blue;
    box-shadow: 0px 0px 4px rgba($zubut-blue, 0.25);
  }
}
</style>
