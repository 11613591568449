const DELIVERY_REQUEST = "Solicitud de Entrega Express";
const GUIDE_REQUEST = "Solicitud de Guía";
const RESERVATION_REQUEST = "Solicitud de Reservación";
const CLIENT_REFUND = "Reembolso a Cliente";
const EXTRA_CHARGE = "Cobro Extra";
const WALLET_DEPOSIT = "Depósito a Wallet";
const CHARGE_ATTEMPT = "Intento de Cobro";
const ROUTE_REQUEST = "Solicitud de Ruta";
const ADDITIONAL_HOUR_REQUEST = "Solicitud de Horas Extras";
const BONUS = "Bono";
const PENALIZATION = "Penalización";
const PARCEL_PICKUP = "Recolección de guía";
const PARCEL_DELIVERY_ATTEMPT = "Intento de entrega";
const PARCEL_DELIVERY = "Entrega de guía";

const NUM_DELIVERY_REQUEST = 0;
const NUM_GUIDE_REQUEST = 1;
const NUM_RESERVATION_REQUEST = 2;
const NUM_CLIENT_REFUND = 3;
const NUM_EXTRA_CHARGE = 4;
const NUM_WALLET_DEPOSIT = 5;
const NUM_CHARGE_ATTEMPT = 6;
const NUM_ROUTE_REQUEST = 7;
const NUM_ADDITIONAL_HOUR_REQUEST = 8;
const NUM_BONUS = 9;
const NUM_PENALIZATION = 10;
const NUM_PARCEL_PICKUP = 11;
const NUM_PARCEL_DELIVERY_ATTEMPT = 12;
const NUM_PARCEL_DELIVERY = 13;

const get = {};
get[NUM_DELIVERY_REQUEST] = DELIVERY_REQUEST;
get[NUM_GUIDE_REQUEST] = GUIDE_REQUEST;
get[NUM_RESERVATION_REQUEST] = RESERVATION_REQUEST;
get[NUM_CLIENT_REFUND] = CLIENT_REFUND;
get[NUM_EXTRA_CHARGE] = EXTRA_CHARGE;
get[NUM_WALLET_DEPOSIT] = WALLET_DEPOSIT;
get[NUM_CHARGE_ATTEMPT] = CHARGE_ATTEMPT;
get[NUM_ROUTE_REQUEST] = ROUTE_REQUEST;
get[NUM_ADDITIONAL_HOUR_REQUEST] = ADDITIONAL_HOUR_REQUEST;
get[NUM_BONUS] = BONUS;
get[NUM_PENALIZATION] = PENALIZATION;
get[NUM_PARCEL_PICKUP] = PARCEL_PICKUP;
get[NUM_PARCEL_DELIVERY_ATTEMPT] = PARCEL_DELIVERY_ATTEMPT;
get[NUM_PARCEL_DELIVERY] = PARCEL_DELIVERY;

export default {
  get,
  DELIVERY_REQUEST,
  GUIDE_REQUEST,
  RESERVATION_REQUEST,
  CLIENT_REFUND,
  EXTRA_CHARGE,
  WALLET_DEPOSIT,
  CHARGE_ATTEMPT,
  ROUTE_REQUEST,
  BONUS,
  PENALIZATION,
  PARCEL_PICKUP,
  PARCEL_DELIVERY_ATTEMPT,
  PARCEL_DELIVERY,
  ADDITIONAL_HOUR_REQUEST,
  NUM_DELIVERY_REQUEST,
  NUM_GUIDE_REQUEST,
  NUM_RESERVATION_REQUEST,
  NUM_CLIENT_REFUND,
  NUM_EXTRA_CHARGE,
  NUM_WALLET_DEPOSIT,
  NUM_CHARGE_ATTEMPT,
  NUM_ROUTE_REQUEST,
  NUM_ADDITIONAL_HOUR_REQUEST,
  NUM_BONUS,
  NUM_PENALIZATION,
  NUM_PARCEL_PICKUP,
  NUM_PARCEL_DELIVERY_ATTEMPT,
  NUM_PARCEL_DELIVERY
};
