const SUCCESSFUL_PAYMENT = "Pago exitoso";
const PAYMENT_CANCELATION = "Pago de cancelacion";
const PAYMENT_ERROR = "Error de pago";
const OXXO_PAYMENT_REQUEST = "Solicitud de recarga OXXO";
const SPEI_PAYMENT_REQUEST = "Solicitud de recarga SPEI";
const WALLET_PAYMENT = "Recarga Wallet";
const CANCELED_WALLET_PAYMENT = "Cancelacion recarga";
const WALLET_MODIFICACTION = "Modificacion de monto Wallet";
const WALLET_REFUND = "Reembolso a Wallet";
const PAYMENT_ERROR_DUE_INCIDENCE = "Sin cobro por incidencia";

const NUM_SUCCESSFUL_PAYMENT = 0;
const NUM_PAYMENT_CANCELATION = 1;
const NUM_PAYMENT_ERROR = 2;
const NUM_OXXO_PAYMENT_REQUEST = 3;
const NUM_SPEI_PAYMENT_REQUEST = 4;
const NUM_WALLET_PAYMENT = 5;
const NUM_CANCELED_WALLET_PAYMENT = 6;
const NUM_WALLET_MODIFICACTION = 7;
const NUM_WALLET_REFUND = 8;
const NUM_PAYMENT_ERROR_DUE_INCIDENCE = 9;

const get = {};
get[NUM_SUCCESSFUL_PAYMENT] = SUCCESSFUL_PAYMENT;
get[NUM_PAYMENT_CANCELATION] = PAYMENT_CANCELATION;
get[NUM_PAYMENT_ERROR] = PAYMENT_ERROR;
get[NUM_OXXO_PAYMENT_REQUEST] = OXXO_PAYMENT_REQUEST;
get[NUM_SPEI_PAYMENT_REQUEST] = SPEI_PAYMENT_REQUEST;
get[NUM_WALLET_PAYMENT] = WALLET_PAYMENT;
get[NUM_CANCELED_WALLET_PAYMENT] = CANCELED_WALLET_PAYMENT;
get[NUM_WALLET_MODIFICACTION] = WALLET_MODIFICACTION;
get[NUM_WALLET_REFUND] = WALLET_REFUND;
get[NUM_PAYMENT_ERROR_DUE_INCIDENCE] = PAYMENT_ERROR_DUE_INCIDENCE;

export default {
  get,
  SUCCESSFUL_PAYMENT,
  PAYMENT_CANCELATION,
  PAYMENT_ERROR,
  OXXO_PAYMENT_REQUEST,
  SPEI_PAYMENT_REQUEST,
  WALLET_PAYMENT,
  CANCELED_WALLET_PAYMENT,
  WALLET_MODIFICACTION,
  WALLET_REFUND,
  PAYMENT_ERROR_DUE_INCIDENCE,
  NUM_SUCCESSFUL_PAYMENT,
  NUM_PAYMENT_CANCELATION,
  NUM_PAYMENT_ERROR,
  NUM_OXXO_PAYMENT_REQUEST,
  NUM_SPEI_PAYMENT_REQUEST,
  NUM_WALLET_PAYMENT,
  NUM_CANCELED_WALLET_PAYMENT,
  NUM_WALLET_MODIFICACTION,
  NUM_WALLET_REFUND,
  NUM_PAYMENT_ERROR_DUE_INCIDENCE
};
