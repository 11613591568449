<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "./StatusBadge";
import TransactionStatus from "../constants/transactions/status";

export default {
  name: "StatusTransaction",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      return TransactionStatus.get[this.status];
    },
    colorVariant() {
      switch (this.status) {
        case TransactionStatus.NUM_PAY:
          return "yellow";
        case TransactionStatus.NUM_PAID:
          return "green";
        case TransactionStatus.NUM_CREDIT:
          return "blue";
        default:
          return "red";
      }
    }
  }
};
</script>
